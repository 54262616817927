<template>
    <section class="compras-listado">
        <tabla-general :mostrarBuscador="false" alturaTabla="500px" :data="purchaseOrderList" :servidor-data="pagination">
            <el-table-column label="Orden" prop="numOrden" align="center" width="80">
                <template slot-scope="scope">
                    <p class="f-12 cr-pointer f-600" @click="verInforOp(scope.row.id)">
                        {{ scope.row.id }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Proyecto" prop="proyecto">
                <template slot-scope="scope">
                    <p class="f-12 tres-puntos">
                        {{ scope.row.oportunidad && scope.row.oportunidad.nombre || '-'}}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Proveedor" prop="proveedor">
                <template slot-scope="scope">
                    <p class="f-12">
                        {{ scope.row.proveedor && scope.row.proveedor.nombre || '-' }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Tipo" prop="tipo" width="100">
                <template>
                    <p class="f-12">
                        Materiales
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Cliente" prop="cliente">
                <template slot-scope="scope">
                    <p class="f-12 tres-puntos">
                        {{ scope.row.oportunidad.cliente }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Estado" prop="estado">
                <template slot-scope="scope">
                    <p class="f-12">
                        <i :class="`f-18 ${purchase_states[scope.row.estado].icon || ''}`" />
                        {{purchase_states[scope.row.estado].text || '-'}}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Valor" prop="valor" align="center">
                <template slot-scope="scope">
                    <p class="f-12">
                        {{ formatoMoneda(scope.row.valor) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Pago" prop="pago">
                <template >
                    {{ '-' }}
                </template>
            </el-table-column>
        </tabla-general>
    </section>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data(){
        return{
        }
    },
    computed: {
        ...mapGetters({
            purchaseOrderList: 'compras/ordenes_compra/purchase_order_list',
            purchase_states: 'compras/ordenes_compra/purchase_states',
            pagination: 'compras/ordenes_compra/pagination',
            filters: 'compras/ordenes_compra/filter_list'
        })
    },
    async created(){
        await this.Action_get_purchase_order_list_values({ id: this.$route.params.id })
        this.clearPurchaseOrderFilter();
        await this.getPurchaseOrderList({ 
            id: this.$route.params.id, 
            // pagination: this.pagination,
            payload: this.filters,
        });
    },
    methods:{
        ...mapActions({
            getPurchaseOrderList: 'compras/ordenes_compra/Action_get_purchase_order_list',
            clearPurchaseOrderFilter: 'compras/ordenes_compra/Action_clear_filter_list',
            Action_get_purchase_order_list_values: 'compras/ordenes_compra/Action_get_purchase_order_list_values',
        }),
        verInforOp(id){
            this.$router.push({ name: 'compras.ver.orden', params:{ id_orden: id} })
        }
    }

}
</script>

<style lang="scss" scoped>
.compras-listado{
    .text-info{
        color:#0090FF;
    }
    .text-orange{
        color:#F96F28;
    }
    .text-verde{
        color:#3CD273;
    }
}

</style>